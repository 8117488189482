import { isStringWithLength, isString, isLogger, isStringArray, isPluginArray, isObject } from './validation.js';

const schema = {
    appVersion: {
        defaultValue: '',
        message: 'should be a string',
        validate: isStringWithLength
    },
    endpoint: {
        defaultValue: 'https://otlp.bugsnag.com/v1/traces',
        message: 'should be a string',
        validate: isStringWithLength
    },
    apiKey: {
        defaultValue: '',
        message: 'should be a 32 character hexadecimal string',
        validate: (value) => isString(value) && /^[a-f0-9]{32}$/.test(value)
    },
    logger: {
        defaultValue: {
            debug(message) { console.debug(message); },
            info(message) { console.info(message); },
            warn(message) { console.warn(message); },
            error(message) { console.error(message); }
        },
        message: 'should be a Logger object',
        validate: isLogger
    },
    releaseStage: {
        defaultValue: 'production',
        message: 'should be a string',
        validate: isStringWithLength
    },
    enabledReleaseStages: {
        defaultValue: null,
        message: 'should be an array of strings',
        validate: (value) => value === null || isStringArray(value)
    },
    plugins: {
        defaultValue: [],
        message: 'should be an array of plugin objects',
        validate: isPluginArray
    }
};
function validateConfig(config, schema) {
    if (typeof config === 'string') {
        config = { apiKey: config };
    }
    if (!isObject(config) || !isString(config.apiKey) || config.apiKey.length === 0) {
        throw new Error('No Bugsnag API Key set');
    }
    let warnings = '';
    const cleanConfiguration = {};
    for (const option of Object.keys(schema)) {
        if (Object.prototype.hasOwnProperty.call(config, option)) {
            if (schema[option].validate(config[option])) {
                cleanConfiguration[option] = config[option];
            }
            else {
                warnings += `\n  - ${option} ${schema[option].message}, got ${typeof config[option]}`;
                cleanConfiguration[option] = schema[option].defaultValue;
            }
        }
        else {
            cleanConfiguration[option] = schema[option].defaultValue;
        }
    }
    // If apiKey is set but not valid we should still use it, despite the validation warning.
    cleanConfiguration.apiKey = config.apiKey;
    cleanConfiguration.maximumBatchSize = config.maximumBatchSize || 100;
    cleanConfiguration.batchInactivityTimeoutMs = config.batchInactivityTimeoutMs || 30 * 1000;
    if (warnings.length > 0) {
        cleanConfiguration.logger.warn(`Invalid configuration${warnings}`);
    }
    return cleanConfiguration;
}

export { schema, validateConfig };
