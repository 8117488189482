import cuid from '@bugsnag/cuid';
import { isPersistedProbability } from './validation.js';

const { isCuid } = cuid;
class InMemoryPersistence {
    constructor() {
        this.persistedItems = new Map();
    }
    async load(key) {
        return this.persistedItems.get(key);
    }
    async save(key, value) {
        this.persistedItems.set(key, value);
    }
}
function toPersistedPayload(key, raw) {
    switch (key) {
        case 'bugsnag-sampling-probability': {
            const json = JSON.parse(raw);
            return isPersistedProbability(json)
                ? json
                : undefined;
        }
        case 'bugsnag-anonymous-id':
            return isCuid(raw)
                ? raw
                : undefined;
    }
}

export { InMemoryPersistence, toPersistedPayload };
